import React, { useEffect, useState } from "react";
import axios from "axios";
import useCurrentWidth from "./useCurrentWidth";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import Modal from "react-bootstrap/Modal"
import { checkRequired, removeOutline } from "../Utils/helpers";
import { Fetcher } from "../Utils/AxiosWrapper";

export default function Registerc() {
  const width = useCurrentWidth();
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const [registrationData, setRegistrationData] = useState({});
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [disable, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);
  const [termsOfService, setTermsOfService] = useState("");
  const validateEmail = function () {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(registrationData.Email).toLowerCase());
  }

  const register = function () {
    if(!validateEmail()){
      pause=false;
      setDisabled(false);
      setError("The email provided is invalid.");
      return;
    }
    if (!checked)
    {
      pause=false;
      setDisabled(false);
      setError("You must accept the terms of service to register.")
      return;
    }
    if (registrationData.Password !== registrationData.ConfirmPassword)
    {
      pause=false;
      setDisabled(false);
      setError("Password and Confirm Password must match.")
      return;
    }
    setError("");
    Fetcher("POST", "/api/Register", registrationData)
    .then((response) => {
      Fetcher("GET", "/api/SendGrid?email="+registrationData.Email+"&emailTemplate=EmailVerification")
      .then((response) => {
        setShow2(!show2);
      })
      .catch((err) => {
        pause=false;
        setDisabled(false);
        setError("error: "+JSON.stringify(err));
       /* if (err.response)
        {
          setError(err.response.data.Message);
        }
        else if (error.message) {
          setError(err.message);
        }
        else {
          setError("An unknown error occured");
        }
        */
      });
    })
    .catch((err) => {
      pause=false;
      setDisabled(false);
      if (err.response && err.response.data && err.response.data.Message)
      {
        setError(err.response.data.Message);
      }
      else{
        setError("error: "+JSON.stringify(err));
      }
      
     /* if (err.response)
      {
        setError(err.response.data.Message);
      }
      else if (error.message) {
        setError(err.message);
      }
      else {
        setError("An unknown error occured");
      }
      */
    });
  }
  const clickHandler = async function (e) {
    e.preventDefault();
    setDisabled(true);
    checkFields();
  };
  const checkFields = function () {
    if (!pause && !checkRequired("passwordForm")) {
      pause = true;
      register();
    } else if (checkRequired("passwordForm")) {
      setDisabled(false);
    }
  };
  
  useEffect(() => {
    axios.get(process.env.GATSBY_API_URL + "/api/TermsOfService")
    .then(({data}) => {setTermsOfService(data.data)}).catch((error) => {console.log(error);});
  }, []);
  return (
    <>
    <div style={{color: `black`, minHeight: `100vh`, display: `flex`}}>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}} onClick={() => {window.location.replace("/")}}>
        <img style={{padding: (width < 700) ? `0px 6vw` : `7vw`, width: `48vw`, height: (width < 700) ? `40vw` : `50vw`}} 
      src={(width < 700) ? "/Images/ReviewHeart.png" : "/Images/LoginLeft.png"}></img></div>
      <div style={{width: `5px`, minHeight: `100vh`, backgroundColor: `#e37100`}}/>
      <div style={{width: `49vw`}}><div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}}>
        <img src="/Images/BottomLogo.png" style={{width: (width < 700) ? `41vw` : `20vw`, height: (width < 700) ? `28vw` : `14vw`, marginTop: `20vh`}}></img></div>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: (width < 375) ? `70vh` : `62vh`}}>
        <div style={{color: `black`, fontSize: (width < 700) ? `12px` : `16px`, fontWeight: `800`}}>
        <div>First Name</div>
        <div className="login" style={{width: (width < 700) ? `41vw` : `28vw`}}>
        <form id="passwordForm">
        <input  type="text" id="firstName" name="firstName" required        
        onChange={(e) =>
          {
            removeOutline(e.target);
            setRegistrationData({ ...registrationData, FirstName: e.target.value })
          }
          }/> 
        <div style={{marginTop: `8px`}}>Last Name</div>
        <input  type="text" id="lastName" name="lastName" required
        onChange={(e) =>
          {
            removeOutline(e.target);
            setRegistrationData({ ...registrationData, LastName: e.target.value })
          }
        }/> 
        <div style={{marginTop: `8px`}}>Email</div>
        <input  type="email" id="email" name="email" required
        onChange={(e) =>{
          removeOutline(e.target);
          setRegistrationData({ ...registrationData, Email: e.target.value })
        }
        }/> 
        <div style={{marginTop: `8px`}}>Password</div>
        <input  type="password" id="password" name="password" required
        onChange={(e) =>{
          removeOutline(e.target);
          setRegistrationData({ ...registrationData, Password: e.target.value })
        }}/>
        <div style={{marginTop: `8px`}}>Confirm Password</div>
        <input  type="password" id="password" name="password" required
        onChange={(e) =>
          {
            removeOutline(e.target);
            setRegistrationData({ ...registrationData, ConfirmPassword: e.target.value })
          }
        }/>
       <div style={{display: `flex`, alignItems: `center`}}>         
       <div style={{marginTop: `12px`}}><Checkbox
          checked={checked}
          icon={
            <span
              style={{
                marginLeft: `2px`,
                marginTop: `3px`,
                flex: 1,
              }}
            >
              <Icon.FiCheck id="checked" color="black" size={15} />
            </span>
          }
          borderColor="black"
          borderWidth={1}
          borderRadius={1}
          size={14}
          onChange={(value) => {
            setChecked(value);
          }}
        /></div>
       <div style={{height: `14px`, marginLeft: `10px`}}>I Accept <span className="loginLink" onClick={handleShow}>Terms of Service</span></div> </div>
        <button type="submit" style={{ cursor: `pointer`, marginTop: `20px`, textTransform: `uppercase`}} onClick={clickHandler}>Register</button></form>
        <div style={{color: `red`, marginTop: '20px'}}>{error}</div>
        </div>
        </div>
        </div>
      </div>
    </div>
    <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={show}
        onHide={handleClose}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(red, rgb(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Terms of Service
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={handleClose}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
          <div dangerouslySetInnerHTML={{ __html: termsOfService }} />
        </Modal.Body>
      </Modal>
      <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={show2}
        onHide={handleClose2}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(red, rgb(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Registration Successful
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={handleClose2}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
          <p>Registration Successful. Please check your email for a verification code.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

var pause = false;
