import React from "react";
import Layout from "../Components/layout";
import Registerc from "../Components/registerc";
import { Seo } from "../Components/seo";


export default function Register() {
  return (
    <Layout>
      <Registerc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)