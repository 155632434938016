import $ from "jquery";

export function checkRequired(formName) {
  const inputs = $("#" + formName + " input");
  let missing = false;
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].value === "" && inputs[i].required) {
      missing = true;
      inputs[i].classList.add("inputFields");
    }
  }
  return missing;
}

export function removeOutline(element) {
  element.classList.remove("inputFields");
}
